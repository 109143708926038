import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {deleteActiveGenderSort, setActiveGenderSort} from "../../helpers/product";
import { isMobileOnly} from "react-device-detect";
import qs from "query-string";
import {useTranslation} from "react-i18next";

const ShopGender = ({ location, currentQuery, setCurrentQuery, genders, getSortParams, setLoadingProducts }) => {
  const [isSmallScreen, setSmallScreen] = useState(false);
  const [appear, setAppear] = useState(true);
  const [display, setDisplay] = useState('none')

  useEffect(() => {
    if (appear) {
        setDisplay('none')
    } else {
        setDisplay('block')
    }
}, [appear])

    const { t } = useTranslation()

  const query = qs.parse(location.search, {
      ignoreQueryPrefix: true
  });
  const filterButtons = document.querySelectorAll(
      ".sidebar-widget-list-gender button"
  );

    filterButtons.forEach(item => {
        item.classList.remove("active");
    });

    if (query && query.genders) {
        let genders = query.genders.split('_');
        genders.map(size => {
            var element = document.getElementById("gender_"+size);
            if (element){
                element.classList.add("active")
            }
        })
    }

    useEffect(() => {
        const matchResult = window.matchMedia("(max-width: 992px)");
        if (matchResult.matches) {
            setSmallScreen(true)
        }
    }, [])

    window.addEventListener("resize", function() {
        if (window.matchMedia("(min-width: 992px)").matches) {
            setSmallScreen(false)
        }
    })

    function constructAddQueryPath(query, size) {
        if (query && query.genders) {
            return query.genders + "_" + size
        }
        return size
    }

  return (
    <div className="sidebar-widget mt-10">
        {
            isMobileOnly || isSmallScreen ?
                <div>
                    <h4 className="pro-sidebar-title" onClick={(e) => setAppear(!appear)} style={{cursor: "pointer"}}>{t('gender')} &nbsp; &nbsp;
                        { appear ?
                            <i className="fas fa-chevron-down"></i>
                            :
                            <i className="fas fa-chevron-up"></i>
                        }
                    </h4>
                    <div className="sidebar-widget-list" style={{display: display}}>
                        {genders ? (
                            <ul>
                                {genders.map((gender, key) => {
                                    return (
                                        <li key={"gender_"+key}>
                                            <div className="sidebar-widget-list-gender">
                                                <button
                                                    className="toto"
                                                    id={"gender_"+gender}
                                                    onClick={e => {
                                                        setLoadingProducts(true)
                                                        if (e.currentTarget.classList.contains('active')) {
                                                            getSortParams("gender", "");
                                                            e.currentTarget.classList.remove('active')
                                                            setCurrentQuery({ ...currentQuery, genders: ConstructRemoveGenderQueryPath(query, gender)});
                                                            deleteActiveGenderSort(e);
                                                        } else {
                                                            getSortParams("gender", gender);
                                                            setCurrentQuery({ ...currentQuery, genders: constructAddQueryPath(query, gender)});
                                                            setActiveGenderSort(e);
                                                        }
                                                    }}
                                                >
                                                    <span className="checkmark" /> {t(gender.toLowerCase())}
                                                </button>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            "No tags found"
                        )}
                    </div>
                </div>
                :
                <div>
                    <h4 className="pro-sidebar-title" onClick={(e) => setAppear(!appear)} style={{cursor: "pointer"}}>{t('gender')} &nbsp; &nbsp;
                        { appear ?
                            <i className="fas fa-chevron-down"></i>
                            :
                            <i className="fas fa-chevron-up"></i>
                        }
                    </h4>
                    <div className="sidebar-widget-list"  style={{display: display, paddingTop: "5px"}}>
                        {genders ? (
                            <ul>
                                {genders.map((gender, key) => {
                                    return (
                                        <li key={"gender_"+key}>
                                            <div className="sidebar-widget-list-gender">
                                            <button
                                                className="toto"
                                                id={"gender_"+gender}
                                                onClick={e => {
                                                    setLoadingProducts(true)
                                                    if (e.currentTarget.classList.contains('active')) {
                                                        getSortParams("gender", "");
                                                        e.currentTarget.classList.remove('active')
                                                        setCurrentQuery({ ...currentQuery, genders: ConstructRemoveGenderQueryPath(query, gender)});
                                                        deleteActiveGenderSort(e);
                                                    } else {
                                                        getSortParams("gender", gender);
                                                        setCurrentQuery({ ...currentQuery, genders: constructAddQueryPath(query, gender)});
                                                        setActiveGenderSort(e);
                                                    }
                                                }}
                                            >
                                                <span className="checkmark" /> {t(gender.toLowerCase())}
                                            </button>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            "No tags found"
                        )}
                    </div>
                </div>
        }
    </div>
  );
};

export function getLabel(gender, t){
    switch (gender){
        case "Women":
            return t('gender')
        case "Men":
            return "Homme"
        case "Unisex":
            return "Unisexe"
        default:
            return
    }
}

export function ConstructRemoveGenderQueryPath(query, sizeToRemove) {
    if (query && query.genders) {
        let genders = query.genders.split('_');
        genders = genders.filter(size => {
            return size !== sizeToRemove;
        })
        if (genders.length > 0) {
            let newSizeQuery = ""
            genders.map((size, index) => {
                if (index == 0){
                    newSizeQuery += size
                } else {
                    newSizeQuery +=  "_" + size
                }
            })
            return newSizeQuery
        } else {
            return null
        }

    }
    return null
}

ShopGender.propTypes = {
  getSortParams: PropTypes.func,
  tags: PropTypes.array,
  currentQuery: PropTypes.object,
  setCurrentQuery: PropTypes.func,
    location: PropTypes.string,
    setLoadingProducts: PropTypes.func

};

export default ShopGender;
