import PropTypes from "prop-types";
import React, {useEffect, Suspense, lazy, useState} from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import Logout from "./components/Logout";
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import axios from "axios";
import MobileBanner from "react-mobile-banner";
import {MobileView, isMobileOnly} from "react-device-detect";
import {getWithExpiry, setWithExpiry} from "./pages/home/HomeFashionTwo";
import {useTranslation} from "react-i18next";
import {LanguagePopup} from "./components/LanguagePop";
import {sleep} from "./helpers/tools";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import PhysicalShopAppointment from "./pages/other/PhysicalShopAppointment";
import PhysicalShopBooking from "./pages/other/PhysicalShopBooking";
import PhysicalShopBookingClinic from "./pages/other/PhysicalShopBookingClinic";
import PhysicalShop from "./pages/other/PhysicalShop";
import Appointments from "./pages/other/Appointments";
import ProductsAppointments from "./pages/other/ProductsAppointments";
import ShopGridFullWidthFavorite from "./pages/shop/ShopGridFullWidthFavorite";
import BecomeAmbassador from "./pages/other/BecomeAmbassador";
import MyRewards from "./pages/other/MyRewards";
import Reward from "./pages/other/Reward";
import AmbassadorReview from "./pages/other/AmbassadorReward";
import AmbassadorReward from "./pages/other/AmbassadorReward";

// home pages
const HomeFashionTwo = lazy(() => import("./pages/home/HomeFashionTwo"));
const HomeFashionTwoAthletisme = lazy(() => import("./pages/home/HomeFashionTwoAthletisme"));
const HomeFashionTwoRunning = lazy(() => import("./pages/home/HomeFashionTwoRunning"));
const HomeFashionTwoTrail = lazy(() => import("./pages/home/HomeFashionTwoTrail"));
const HomeFashionTwoOffers = lazy(() => import("./pages/home/HomeFashionTwoOffers"));

const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
const ShopGridFullWidthResearched = lazy(() => import("./pages/shop/ShopGridFullWidthResearched"));

// product pages
const Product = lazy(() =>   import("./pages/shop-product/ProductTabRight"));

// Physical Events
const PhysicalEvents = lazy(() => import("./pages/other/PhysicalEvents"));
const PhysicalEvent = lazy(() => import("./pages/other/PhysicalEvent"));

// podcasts pages
const NewPodcasts = lazy(() => import("./pages/podcast/NewPodcastNoSideBar"));
const NewAllPodcasts = lazy(() => import("./pages/podcast/NewAllPodcastNoSideBar"));
const NewPodcast = lazy(() => import("./pages/podcast/NewPodcast"));

// wheel spin
const WheelSpin = lazy(() => import("./pages/other/WheelSpin"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Cgu = lazy(() => import("./pages/other/Cgu"));
const Cgv = lazy(() => import("./pages/other/Cgv"));
const PolitiqueProtectionDonnees = lazy(() => import("./pages/other/PolitiqueProtectionDonnees"));
const PaiementSecurise = lazy(() => import("./pages/other/PaiementSecurise"));
const MentionsLegales = lazy(() => import("./pages/other/MentionsLegales"));
const Clubs = lazy(() => import("./pages/other/Clubs"));
const TempoRunClub = lazy(() => import("./pages/other/TempoRunClub"));
const CommentCaMarche = lazy(() => import('./pages/other/CommentCaMarche'))
const NosPartenaires = lazy(() => import('./pages/other/NosPartenaires'))
const Allures = lazy(() => import('./pages/other/Allures'))
const QRCodeEvent = lazy(() => import("./pages/other/QRCodeEvent"));
const QRCodeBooking = lazy(() => import("./pages/other/QRCodeBooking"));

const MyAccount = lazy(() => import("./pages/other/MyAccount"));

const Forecast = lazy(() => import("./pages/other/Forecast"));
const ForecastContent = lazy(() => import("./pages/other/ForecastContent"));
const ForecastContents = lazy(() => import("./pages/other/ForecastContents"));
const ForecastContentsUser = lazy(() => import("./pages/other/ForecastContentsUser"));

const ForecastLeagues = lazy(() => import("./pages/other/ForecastLeagues"));

const NotFound = lazy(() => import("./pages/other/NotFound"));
const NotConnected = lazy(() => import("./pages/other/NotConnected"));

const Redirection = lazy(() => import("./pages/other/Redirection"));
const RedirectionWithVoucher = lazy(() => import("./pages/other/RedirectionWithVoucher"));

const ShortLink = lazy(() => import("./pages/other/ShortLink"));
const QRCodeRedirection = lazy(() => import("./pages/other/QRCodeRedirection"));
const CornerRedirection = lazy(() => import("./pages/other/CornerRedirection"));

const ActivateUser = lazy(() => import("./pages/other/ActivateUser"));
const Games = lazy(() => import("./pages/other/Games"));
const Races = lazy(() =>   import("./pages/other/Races"));
const Race = lazy(() =>   import("./pages/other/Race"));
const StravaValidated = lazy(() =>   import("./pages/other/StravaValidated"));

const SignInV2 = lazy(() => import("./pages/other/SignInV2"));
const SignUpV2 = lazy(() => import("./pages/other/SignUpV2"));
const RenewPasswordV2 = lazy(() => import("./pages/other/RenewPasswordV2"));

const getToken = () => {
  const tokenString = localStorage.getItem('trc-client-token');
  const userToken = JSON.parse(tokenString);
  return userToken?.token
};

export const getClientTokenData = () => {
  const tokenString = localStorage.getItem('trc-client-token');
  const userToken = JSON.parse(tokenString);
  return userToken?.user
};

//creating function to load ip address from the API
export const GetUserData = async (noSending, origin) => {
  var url = "https://pro.ip-api.com/json/?key=Y1hZznfd2inZrpm&fields=status,message,continent,continentCode,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,offset,currency,isp,org,as,asname,reverse,mobile,proxy,hosting,query"
  const res = await axios.get(url)
  if (res.data){
    res.data.location = window.location.href
    var query = "https://public-front-api.therunningcollective.fr/data"
    if (!noSending) {
      var clientData = getClientTokenData()
      if (clientData) {
        res.data.email = clientData.email
        res.data.login = clientData.login
        res.data.firstName = clientData.firstName
        res.data.lastName = clientData.name
        res.data.clientID = clientData.id
      }
      axios.post(query, JSON.stringify(res.data))
    }
    return res.data
  }
}

const App = (props) => {
  const { i18n } = useTranslation();

  const [origin, setOrigin] = useState("fr")
  const [isDifferentOrigin, setIsDifferentOrigin] = useState(false)
  const [actualCountry, setActualCountry] = useState("fr")
  const [actualCountryC, setActualCountryC] = useState("France")

  useEffect(() => {
    setOrigin(i18n.language)
    document.documentElement.setAttribute('lang', i18n.language);
  }, [])


  useEffect(() => {
    GetUserData().then(
        data => {
          apiConversionRequest(data.query, "PageView")
          if(data.countryCode && data.countryCode.toLowerCase() && origin && (data.countryCode.toLowerCase() != origin)){
            if(!getWithExpiry('trc-lang-popup')){
              sleep(5000).then(() => {
                setIsDifferentOrigin(true)
                setActualCountry(data.countryCode)
                setActualCountryC(data.country)
              })
            }
        }}
    )
    props.dispatch(
      loadLanguages({
        languages: {
        }
      })
    );
  });

  let [token] = useState(getToken());

  ReactGA.initialize(
      [
        {
          trackingId: "G-XLY8JQ932W",
        },
      ], {testMode: false});

  useEffect(() => {
    ReactPixel.init('1219438635083129', {}, { debug: false, autoConfig: true });
    ReactPixel.pageView();
    ReactPixel.fbq('track', 'PageView');

  }, [])

  const [bannerShow, setBannerShow] = useState(false);

  const closeAppliBanner = () => {
    setWithExpiry("trc-banner-appli", "false", 566400000)
    setBannerShow(false)
  }

  const setAlt = () => {
    var div = document.querySelector(".styles_bannerIcon__3zngJ");
    if (div){
      div.setAttribute("alt", "application The Running Collective");
    }
  }

  useEffect(() => {
    let trcBanerValue = getWithExpiry("trc-banner-appli")
    if (!trcBanerValue && window.location && window.location.pathname && 
      !window.location.pathname.includes("/sign-in") && 
      !window.location.pathname.includes("/sign-up") && 
      !window.location.pathname.includes("/login-register")){
      setBannerShow(true)
      window.addEventListener('DOMContentLoaded', (event) => {
        setAlt()
      });
    }
  }, [])

  useEffect(() => {
    if (window.location && window.location.search){
      const search = window.location.search; // could be '?foo=bar'
      const params = new URLSearchParams(search);
      const source = params.get('source'); // bar
      if (source){
        setWithExpiry("trc-source", source, 566400000)
      }
    }
  }, [])

  const theme = createTheme({
    typography: {
      fontFamily: [
        'ITC Avant Garde Gothic'
      ].join(','),
    }
  });

  return (
      <ThemeProvider theme={theme}>

      <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>

        <Router>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                    exact
                    path={process.env.PUBLIC_URL + "/physical-events-validation/:id"}
                    render={(routeProps) => (
                        <QRCodeEvent {...routeProps} key={routeProps.match.params.id}  origin={origin} />
                    )}
                />

                <Route
                    exact
                    path={process.env.PUBLIC_URL + "/physical-shop-product-purchase/:id"}
                    render={(routeProps) => (
                        <QRCodeBooking {...routeProps} key={routeProps.match.params.id}  origin={origin} />
                    )}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  render={(routeProps) => (
                      <HomeFashionTwo {...routeProps} key={routeProps.match.params.id}  origin={origin} />
                  )}
                />

                <Route
                    exact
                    path={process.env.PUBLIC_URL + "/athletisme"}
                    render={(routeProps) => (
                        <HomeFashionTwoAthletisme {...routeProps} key={routeProps.match.params.id}  origin={origin} />
                    )}
                />

                <Route
                    exact
                    path={process.env.PUBLIC_URL + "/running"}
                    render={(routeProps) => (
                        <HomeFashionTwoRunning {...routeProps} key={routeProps.match.params.id}  origin={origin} />
                    )}
                />

                <Route
                    path={process.env.PUBLIC_URL + "/offers/:id"}
                    render={(routeProps) => (
                        <HomeFashionTwoOffers {...routeProps} key={routeProps.match.params.id}  origin={origin} />
                    )}
                />

                <Route
                    exact
                    path={process.env.PUBLIC_URL + "/trail"}
                    render={(routeProps) => (
                        <HomeFashionTwoTrail {...routeProps} key={routeProps.match.params.id}  origin={origin} />
                    )}
                />

                <Route
                    path={process.env.PUBLIC_URL + "/tempo-run-club"}
                    component={TempoRunClub}
                />

                <Route
                    path={process.env.PUBLIC_URL + "/clubs"}
                    component={Clubs}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/comment-ca-marche"}
                  component={CommentCaMarche}
                />

                <Route
                    path={process.env.PUBLIC_URL + "/races"}
                    component={Races}
                />

                <Route
                    path={process.env.PUBLIC_URL + "/become-ambassador"}
                    component={BecomeAmbassador}
                />

                <Route
                    path={process.env.PUBLIC_URL + "/physical-events"}
                    component={PhysicalEvents}
                />

                {token ?
                    <Route
                        path={process.env.PUBLIC_URL + "/physical-event/:id"}
                        render={(routeProps) => (
                            <PhysicalEvent {...routeProps} key={routeProps.match.params.id} origin={origin} />
                        )}
                    />
                    :  <Route
                        path={process.env.PUBLIC_URL + "/physical-event"}
                        component={NotConnected}
                    />}

                {
                  !token?
                      <Route
                          path={process.env.PUBLIC_URL + "/wheel-spin"}
                          component={NotConnected}
                      ></Route>
                      : <Route
                          path={process.env.PUBLIC_URL + "/wheel-spin/:id"}
                          render={(routeProps) => (
                              <WheelSpin {...routeProps} key={routeProps.match.params.id} origin={origin} />
                          )}
                      />
                }

                <Route
                    path={process.env.PUBLIC_URL + "/race/:id"}
                    render={(routeProps) => (
                        <Race {...routeProps} key={routeProps.match.params.id} origin={origin} />
                    )}
                />

                <Route
                    path={process.env.PUBLIC_URL + "/qui-sont-nos-partenaires"}
                    component={NosPartenaires}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/forecast/:id"}
                  render={(routeProps) => (
                      <Forecast {...routeProps} key={routeProps.match.params.id} origin={origin} />
                  )}
                />    

                <Route
                  path={process.env.PUBLIC_URL + "/forecast-contents/:id"}
                  render={(routeProps) => (
                      <ForecastContents {...routeProps} key={routeProps.match.params.id} origin={origin} />
                  )}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/forecast-contents-user/:id"}
                  render={(routeProps) => (
                      <ForecastContentsUser {...routeProps} key={routeProps.match.params.id} origin={origin} />
                  )}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/forecast-leagues/:id"}
                  render={(routeProps) => (
                      <ForecastLeagues {...routeProps} key={routeProps.match.params.id} origin={origin} />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/forecast-content/:id"}
                  render={(routeProps) => (
                      <ForecastContent {...routeProps} key={routeProps.match.params.id} origin={origin} />
                  )}
                />

                {/* Shop pages */}

                <Route
                  path={process.env.PUBLIC_URL + "/products"}
                  render={(routeProps) => (
                      <ShopGridFullWidth {...routeProps} origin={origin} />
                  )}
                />
                <Route
                    path={process.env.PUBLIC_URL + "/products-researched"}
                    component={ShopGridFullWidthResearched}
                />
                <Route
                    path={process.env.PUBLIC_URL + "/products-researched/:id"}
                    render={(routeProps) => (
                        <ShopGridFullWidthResearched {...routeProps} key={routeProps.match.params.id} origin={origin} />
                    )}
                />
                <Route
                    path={process.env.PUBLIC_URL + "/products/:id"}
                    render={(routeProps) => (
                        <ShopGridFullWidth {...routeProps} key={routeProps.match.params.id} origin={origin} />
                    )}
                />
                <Route
                    path={process.env.PUBLIC_URL + "/products/:id/:type"}
                    render={(routeProps) => (
                        <ShopGridFullWidth {...routeProps} key={routeProps.match.params.id} origin={origin} />
                    )}
                />

                {/* Shop product pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} origin={origin} />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/physical-shop/:id"}
                  component={PhysicalShop}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/physical-shop-booking"}
                  component={PhysicalShopBooking}
                /> 

                <Route
                  path={process.env.PUBLIC_URL + "/physical-shop-appointment/:id"}
                  component={PhysicalShopAppointment}
                /> 

                <Route
                  path={process.env.PUBLIC_URL + "/links/:id"}
                  component={ShortLink}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/physical-shop-booking-clinic"}
                  component={PhysicalShopBookingClinic}
                /> 

                {/* Blog pages */}
                <Route 
                  path={process.env.PUBLIC_URL + "/blog"}
                  component={() => {
                    window.location.href = 'https://blog.therunningcollective.fr';
                    return null;
                }}/>
                

                <Route 
                    path={process.env.PUBLIC_URL + "/post/:id"}
                    render={(routeProps) => {
                    window.location.href = 'https://blog.therunningcollective.fr/'+routeProps.match.params.id;
                    return null;
                }}/>  
                
                  

                {/* Podcasts pages */}
                <Route
                    path={process.env.PUBLIC_URL + "/podcasts"}
                    component={NewPodcasts}
                />

                <Route
                    path={process.env.PUBLIC_URL + "/new-podcasts"}
                    component={NewPodcasts}
                />

                <Route
                    path={process.env.PUBLIC_URL + "/new-all-podcasts"}
                    component={NewAllPodcasts}
                />

                <Route
                    path={process.env.PUBLIC_URL + "/podcast/:id"}
                    render={(routeProps) => (
                        <NewPodcast {...routeProps} key={routeProps.match.params.id} />
                    )}
                />

                {/* Other pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route
                    path={process.env.PUBLIC_URL + "/conditions-generales-utilisation"}
                    component={Cgu}
                />
                <Route
                    path={process.env.PUBLIC_URL + "/conditions-generales-ventes"}
                    component={Cgv}
                />
                <Route
                    path={process.env.PUBLIC_URL + "/paiement-securise"}
                    component={PaiementSecurise}
                />
                <Route
                    path={process.env.PUBLIC_URL + "/mentions-legales"}
                    component={MentionsLegales}
                />
                <Route
                    path={process.env.PUBLIC_URL + "/politique-protection-donnees"}
                    component={PolitiqueProtectionDonnees}
                />
                <Route
                    path={process.env.PUBLIC_URL + "/logout"}
                    component={Logout}
                />
                <Route
                    path={process.env.PUBLIC_URL + "/renew-password"}
                    component={RenewPasswordV2}
                ></Route>
                {!token ?
                    <Route
                        path={process.env.PUBLIC_URL + "/login-register"}
                        component={SignInV2}
                    ></Route>
                    :
                    <Route
                        path={process.env.PUBLIC_URL + "/my-account"}
                        component={MyAccount}
                    />
                }

                {!token ?
                    <Route
                        path={process.env.PUBLIC_URL + "/sign-in"}
                        component={SignInV2}
                    ></Route>
                    :
                    <Route
                        path={process.env.PUBLIC_URL + "/my-account"}
                        component={MyAccount}
                    />
                }

                {!token ?
                    <Route
                        path={process.env.PUBLIC_URL + "/sign-up"}
                        component={SignUpV2}
                    ></Route>
                    :
                    <Route
                        path={process.env.PUBLIC_URL + "/sign-up"}
                        component={HomeFashionTwo}
                    />
                }

                <Route
                    path={process.env.PUBLIC_URL + "/games"}
                    component={Games}
                />


                {!token ?
                  <Route
                      path={process.env.PUBLIC_URL + "/login-register"}
                      component={SignInV2}
                  ></Route>
                  :
                    <Route
                      path={process.env.PUBLIC_URL + "/appointments"}
                      component={Appointments}
                    /> 
                }

                {!token ?
                  <Route
                      path={process.env.PUBLIC_URL + "/login-register"}
                      component={SignInV2}
                  ></Route>
                  :
                    <Route
                      path={process.env.PUBLIC_URL + "/my-ambassador"}
                      component={ProductsAppointments}
                    /> 
                }

                {!token ?
                  <Route
                      path={process.env.PUBLIC_URL + "/login-register"}
                      component={SignInV2}
                  ></Route>
                  :
                    <Route
                      path={process.env.PUBLIC_URL + "/my-rewards"}
                      component={MyRewards}
                    /> 
                }  

                {!token ?
                  <Route
                      path={process.env.PUBLIC_URL + "/login-register"}
                      component={SignInV2}
                  ></Route>
                  :
                    <Route
                      path={process.env.PUBLIC_URL + "/menu"}
                      component={MyRewards}
                    /> 
                }  

                {!token ?
                  <Route
                      path={process.env.PUBLIC_URL + "/login-register"}
                      component={SignInV2}
                  ></Route>
                :
                  <Route
                    path={process.env.PUBLIC_URL + "/reward/:id"}
                    render={(routeProps) => (
                      <Reward {...routeProps} key={routeProps.match.params.id} />
                    )}
                  /> 
                } 

                {!token ?
                  <Route
                      path={process.env.PUBLIC_URL + "/login-register"}
                      component={SignInV2}
                  ></Route>
                :
                  <Route
                    path={process.env.PUBLIC_URL + "/ambassador-reward/:id"}
                    render={(routeProps) => (
                      <AmbassadorReward {...routeProps} key={routeProps.match.params.id} />
                    )}
                  /> 
                } 

                {
                    token ?
                    <Route
                      path={process.env.PUBLIC_URL + "/favorites"}
                      render={(routeProps) => (
                          <ShopGridFullWidthFavorite {...routeProps} origin={origin} />
                      )}
                    /> 
                    : 
                    <Route
                        path={process.env.PUBLIC_URL + "/favorites"}
                        component={SignInV2}
                    ></Route>
                }   
                   

                <Route
                    path={process.env.PUBLIC_URL + "/pace"}
                    component={Allures}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />

                <Route
                    path={process.env.PUBLIC_URL + "/redirection"}
                    component={Redirection}
                />

                <Route
                    path={process.env.PUBLIC_URL + "/redirection-with-voucher"}
                    component={RedirectionWithVoucher}
                />

                <Route
                    path={process.env.PUBLIC_URL + "/corner-redirection"}
                    component={CornerRedirection}
                />

                <Route
                    path={process.env.PUBLIC_URL + "/qr-code-redirection"}
                    component={QRCodeRedirection}
                />

                <Route
                    path={process.env.PUBLIC_URL + "/strava-validated"}
                    component={StravaValidated}
                />

                <Route
                    path={process.env.PUBLIC_URL + "/activate-user"}
                    component={ActivateUser}
                />

                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          {/*
            isDifferentOrigin ?
                <LanguagePopup show={isDifferentOrigin}
                               setShow={setIsDifferentOrigin}
                               actualCountry={actualCountry.toLowerCase()}
                               actualCountryC={actualCountryC}
                expectedCountry={origin}/>
                : null
          */}
          {
            origin == "fr" ?
                <MobileView>
                  <MobileBanner
                      showBanner={bannerShow}
                      position="bottom"
                      topStickyBanner={true}
                      appTitle="Obtenir l'appli TRC"
                      appDescription="Inscris-toi et obtiens 100 TRCoins pour profiter d’offres encore meilleures !"
                      appId={{android:'io.ionic.therunningcollective',ios:'id1638209260'}}
                      starRating={false}
                      backgroundColor="white"
                      appIcon={require('./icon.png')}
                      appIconStyle={{border: "1px solid black", borderRadius: "15px"}}
                      closeButtonStyle={{padding: "10px", paddingTop: "25px"}}
                      onClose={()=> closeAppliBanner()}
                      buttonText="Installer"
                      appTitleStyles={{fontSize: "14px", fontWeight: 500}}
                      appDescriptionStyle={{lineHeight: "14px"}}
                  />
                </MobileView>
                : null
          }

        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
      </ThemeProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(multilanguage(App));

export function apiConversionRequest(queryUser, eventName, eventData){
  let fbp = document.cookie.split(';').filter(c => c.includes('_fbp=')).map(c => c.split('_fbp=')[1]);
  let fbc = document.cookie.split(';').filter(c => c.includes('_fbc=')).map(c => c.split('_fbc=')[1]);
  fbp = (fbp.length && fbp[0]) || null;
  fbc = (fbc.length && fbc[0]) || null;

  if(!fbc && window.location.search.includes('fbclid=')){
    fbc = 'fb.1.'+ (+new Date()) +'.'+ window.location.search.split('fbclid=')[1];
  }

  let body = {};

  if (eventData && eventData != ""){
    body = {
      "data": [
        {
          "event_name": eventName,
          "event_time": new Date().toISOString(),
          "user_data": {
            "fbp": fbp,
            "fbc": fbc,
            "client_user_agent": navigator.userAgent,
            "client_ip_address": queryUser,
          },
          "custom_data": {
            "value": eventData.value,
            "currency": "EUR",
            "content_ids": [
                eventData.productID
            ],
            "content_name": eventData.productName,
            "content_type": "product"
          },
          "event_source_url": window.location.origin + window.location.pathname,
          "action_source": "website"
        }
      ]
    }
  } else if (eventData && eventData != "Search"){
    body = {
      "data": [
        {
          "event_name": eventName,
          "event_time": new Date().toISOString(),
          "user_data": {
            "fbp": fbp,
            "fbc": fbc,
            "client_user_agent": navigator.userAgent,
            "client_ip_address": queryUser,
          },
          "custom_data": {
            "search_string": eventData.search,
            "content_type": "product"
          },
          "event_source_url": window.location.origin + window.location.pathname,
          "action_source": "website"
        }
      ]
    }
  } else {
    body = {
      "data": [
        {
          "event_name": eventName,
          "event_time": new Date().toISOString(),
          "user_data": {
            "fbp": fbp,
            "fbc": fbc,
            "client_user_agent": navigator.userAgent,
            "client_ip_address": queryUser,
          },
          "event_source_url": window.location.origin + window.location.pathname,
          "action_source": "website"
        }
      ]
    }
  }
  let query = "https://graph.facebook.com/v13.0/1219438635083129/events?access_token=EAAStngtBnw0BAEbbRh60krdUcZCFix7MLaxQoh59ZCgeWZAubEv27fBp2nFNS7r5kJKum4BfbhygIhUfyRFkfdq9sfpD4ege2ZCBMzZAYwJn5yFpI6QTcC2BySqMnmNAIXMysobqP3XmYjGjLqfVDKCEukJ2aGiYZCBGdIxb6h05uuDq9ZBUocj"

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };
  fetch(query, requestOptions)
      .then(response => response.json())
}