import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {setActiveSizesSort} from "../../helpers/product";
import {isMobileOnly} from "react-device-detect";
import qs from "query-string";
import {useTranslation} from "react-i18next";

const ShopRetailer = ({ location, currentQuery, setCurrentQuery, retailers, getSortParams, setLoadingProducts }) => {
  const [hide, setHide] = useState(true)
  const [isSmallScreen, setSmallScreen] = useState(false);
  const [display, setDisplay] = useState('none')
  const { t } = useTranslation()
  
  useEffect(() => {
    if (hide) {
        setDisplay('none')
    } else {
        setDisplay('block')
    }
}, [hide])

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true
  });
  const filterButtons = document.querySelectorAll(
      ".sidebar-widget-list-retailer button"
  );

  filterButtons.forEach(item => {
    item.classList.remove("active");
  });

  if (query && query.retailers) {
    let sizes = query.retailers.split('_');
    sizes.map(size => {
      var element = document.getElementById("retailer_"+size);
      if (element){
        element.classList.add("active")
      }
    })
  }

  useEffect(() => {
    const matchResult = window.matchMedia("(max-width: 992px)");
    if (matchResult.matches) {
      setSmallScreen(true)
    }
  }, [])

  useEffect(() => {
    window.addEventListener("resize", function() {
      if (window.matchMedia("(min-width: 992px)").matches) {
        setSmallScreen(false)
      }
    })
  }, [])

  function constructAddQueryPath(query, size) {
    if (query && query.retailers) {
      return query.retailers + "_" + size
    }
    return size
  }

  function constructRemoveQueryPath(query, sizeToRemove) {
    if (query && query.retailers) {
      let sizes = query.retailers.split('_');
      sizes = sizes.filter(size => {
        return size !== sizeToRemove;
      })
      if (sizes.length > 0) {
        let newSizeQuery = ""
        sizes.map((size, index) => {
          if (index == 0){
            newSizeQuery += size
          } else {
            newSizeQuery +=  "_" + size
          }
        })
        return newSizeQuery
      } else {
        return null
      }

    }
    return null
  }

  return (
    <div className="sidebar-widget mt-10">
      {
        isMobileOnly || isSmallScreen ?
            <div>
              <h4 className="pro-sidebar-title" onClick={(e) => setHide(!hide)} style={{cursor: "pointer"}}>{t('Distributeurs')} &nbsp; &nbsp;
                  { hide ?
                      <i className="fas fa-chevron-down"></i>
                      :
                      <i className="fas fa-chevron-up"></i>
                  }
              </h4>
              <div className="sidebar-widget-list" style={{display: display, paddingTop: "5px", maxHeight: "230px", overflow: "auto"}}>
                {retailers ? (
                    <ul>
                      {retailers.map((retailer, key) => {
                        return (
                            <li key={"retailer_"+key}>
                              <div className="sidebar-widget-list-retailer">
                                <button
                                    id={"retailer_"+retailer.name}
                                    onClick={e => {
                                      setLoadingProducts(true)
                                      if (e.currentTarget.classList.contains('active')) {
                                        getSortParams("retailer", "");
                                        setCurrentQuery({ ...currentQuery, retailers: constructRemoveQueryPath(query, retailer.name)});
                                      } else {
                                        getSortParams("retailer", retailer.name);
                                        setCurrentQuery({ ...currentQuery, retailers: constructAddQueryPath(query, retailer.name)});
                                      }
                                      setActiveSizesSort(e);
                                    }}
                                >
                                  <span className="checkmark" /> {retailer.name}
                                </button>
                              </div>
                            </li>
                        );
                      })}
                    </ul>
                ) : (
                    "Aucune taille disponible"
                )}
              </div>

            </div>
            :
            <div>
              <h4 className="pro-sidebar-title" onClick={(e) => setHide(!hide)} style={{cursor: "pointer"}}>{t('Distributeurs')} &nbsp; &nbsp;
                  { hide ?
                      <i className="fas fa-chevron-down"></i>
                      :
                      <i className="fas fa-chevron-up"></i>
                  }
              </h4>
              <div className="sidebar-widget-list" style={{display: display, paddingTop: "5px"}}>
                {retailers ? (
                    <ul>
                      {retailers.map((retailer, key) => {
                        return (
                            <li key={"retailer_"+key}>
                              <div className="sidebar-widget-list-brand">
                                <button
                                    id={"retailer_"+retailer.name}
                                    onClick={e => {
                                      setLoadingProducts(true)
                                      if (e.currentTarget.classList.contains('active')) {
                                        getSortParams("retailer", "");
                                        setCurrentQuery({ ...currentQuery, retailers: constructRemoveQueryPath(query, retailer.name)});
                                      } else {
                                        getSortParams("retailer", retailer.name);
                                        setCurrentQuery({ ...currentQuery, retailers: constructAddQueryPath(query, retailer.name)});
                                      }
                                      setActiveSizesSort(e);
                                    }}
                                >
                                  <span className="checkmark" /> {retailer.name}
                                </button>
                              </div>
                            </li>
                        );
                      })}
                    </ul>
                ) : (
                    "Aucune taille disponible"
                )}
              </div>
            </div>

      }
    </div>
  );
};

ShopRetailer.propTypes = {
  getSortParams: PropTypes.func,
  retailers: PropTypes.any,
  currentQuery: PropTypes.object,
  setCurrentQuery: PropTypes.func,
  location: PropTypes.string,
};

export default ShopRetailer;
