import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
    getIndividualCategories,
    getIndividualTags,
    getIndividualColors,
    getProductsIndividualSizes, getIndividualGenders, getIndividualRetailers, getShowProducts
} from "../../helpers/product";

import ShopCategories from "../../components/product/ShopCategories";
import ShopColor from "../../components/product/ShopColor";
import ShopSize from "../../components/product/ShopSize";
import ShopTag from "../../components/product/ShopTag";
import ShopGender from "../../components/product/ShopGender";
import ShopMultiRangeSlider from "../../components/MultiRangeSlider";
import ShopRetailer from "../../components/product/ShopRetailer";
import ShopTagColor from "../../components/product/ShopTagColor";
import ShopMultiRangeDropSlider from "../../components/MultiRangeSliderDrop";
import ShopMultiRangeWeighSlider from "../../components/MultiRangeSliderWeigh";

const ShopSidebar = ({ location,
                       currentQuery,
                       setCurrentQuery,
                       products,
                       getCategorySortParams,
                       getBrandSortParams,
                       getGenderSortParams,
                       getColorSortParams,
                       getSizeSortParams,
                       getPriceSortParams,
                       getRetailerSortParams,
                       sideSpaceClass,
                       sortedHighPrice,
                       sortedLowPrice,
                       uniqueGenders,
                       uniqueSizes,
                       uniqueBrands,
                       uniqueColors,
                       uniqueRetailers,
                       uniqueCategories,
                       setLoadingProducts
                     }) => {

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(location && location.pathname == "/products/electronic" ? 1500 : 500);

  return (
    <div className={`sidebar-style ${sideSpaceClass ? sideSpaceClass : ""}`}>
      {/* shop search */}
      {/*<ShopSearch />*/}

      {/* filter by gender */}
        {
            location.pathname != "/products/vouchers"
                ?
      <ShopGender
          location={location}
          genders={uniqueGenders}
          getSortParams={getGenderSortParams}
          currentQuery={currentQuery}
          setCurrentQuery={setCurrentQuery}
          setLoadingProducts={setLoadingProducts}
      /> : null }

           {/* filter by size */}
      {
        location.pathname != "/products/accessories" && location.pathname != "/products/nutrition" && location.pathname != "/products/electronic" && location.pathname != "/products/vouchers"
        ?
            <ShopSize
                location={location}
                currentQuery={currentQuery}
                setCurrentQuery={setCurrentQuery}
                sizes={uniqueSizes}
                getSortParams={getSizeSortParams} 
                setLoadingProducts={setLoadingProducts}
            />
            : null

        }

        {/* filter by price */}
        <ShopMultiRangeSlider sortedHighPrice={maxPrice}
                        currentQuery={currentQuery}
                        setCurrentQuery={setCurrentQuery}
                        sortedLowPrice={minPrice}
                        getSortParams={getPriceSortParams}
                        setLoadingProducts={setLoadingProducts}
        />

      {/* filter by categories */}
        {
            location.pathname != "/products/vouchers"  && location.pathname !== "/products"
                ?
      <ShopCategories
          location={location}
          categories={uniqueCategories}
        getSortParams={getCategorySortParams}
        currentQuery={currentQuery}
        setCurrentQuery={setCurrentQuery}
        setLoadingProducts={setLoadingProducts}
      /> : null }

        {/* filter by tag */}
        {
            location.pathname != "/products/vouchers"
                ?
        <ShopTag
            location={location}
            currentQuery={currentQuery}
            setCurrentQuery={setCurrentQuery}
            tags={uniqueBrands}
            getSortParams={getBrandSortParams} 
            setLoadingProducts={setLoadingProducts}
        /> : null }

        {/* filter by color */}
        {
            location.pathname != "/products/nutrition"&& location.pathname != "/products/vouchers"
                ?
                <ShopTagColor
                    location={location}
                    currentQuery={currentQuery}
                    setCurrentQuery={setCurrentQuery}
                    colors={uniqueColors}
                    getSortParams={getColorSortParams} 
                    setLoadingProducts={setLoadingProducts}
                    /> : null
        }


        {/* filter by size */}
        <ShopRetailer
            location={location}
            currentQuery={currentQuery}
            setCurrentQuery={setCurrentQuery}
            retailers={uniqueRetailers}
            getSortParams={getRetailerSortParams}
            setLoadingProducts={setLoadingProducts}
            />
    </div>
  );
};

ShopSidebar.propTypes = {
  getSortParams: PropTypes.func,
  getCategorySortParams: PropTypes.func,
  getGenderSortParams: PropTypes.func,
  getColorSortParams: PropTypes.func,
  getSizeSortParams: PropTypes.func,
  getPriceSortParams: PropTypes.func,
  getRetailerSortParams: PropTypes.func,
  products: PropTypes.array,
  sortedLowPrice: PropTypes.any,
  sortedHighPrice: PropTypes.any,
  sideSpaceClass: PropTypes.string,
  currentQuery: PropTypes.object,
  setCurrentQuery: PropTypes.func,
    location: PropTypes.string,
    setLoadingProducts: PropTypes.func
};

export default ShopSidebar;
