import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import {ButtonGroup, Col, Row, Spinner, ToggleButton} from "react-bootstrap";
import {connect} from "react-redux";
import axios from "axios";
import parse from "react-html-parser";
import {Link} from "react-router-dom";
import ReactGA from "react-ga4";
import {getCookie, redirection, redirectionWithVoucher, setCookie} from "../../components/product/ProductDescriptionInfo";
import ReactStars from "react-rating-stars-component/dist/react-stars";
import {sleep} from "../../helpers/tools";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'
import {getHexaColorFromName, getRGBColorFromName} from "../../helpers/product";
import ReactPixel from "react-facebook-pixel";
import {apiConversionRequest, getClientTokenData, GetUserData} from "../../App";
import {sortSize} from "../../components/product/ShopSize";
import {useTranslation} from "react-i18next";
import {ReactComponent as ScissorsLogo} from '../../assets/img/scissors-svgrepo-com.svg';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
)

const ProductDescriptionTab = ({ spaceBottomClass, currentDeclination, posts, product, othersSizesRef, productRating, historyPrices, setHistoryPeriod, currentTab, setCurrentTab, setAverageHistory, selectedUserSize }) => {
  const [avalaibleMerchands, setAvalaibleMerchands] = useState([]);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [adidasVoucher, setAdidasVoucher] = useState(null)
  const [showVoucher, setShowVoucher] = useState(false)

  const [ratingStars, setRatingStars] = useState(null);
  const [ratingName, setRatingName] = useState(null);
  const [ratingMessage, setRatingMessage] = useState(null);
  const [ratingEmail, setRatingEmail] = useState(null);
  const [ratingError, setRatingError] = useState(null);
  const [ratingSuccess, setRatingSuccess] = useState(null);
  const [avalaibleColors, setAvalaibleColors] = useState([]);
  const [radioValue, setRadioValue] = useState('year');
  const [averagePrice, setAveragePrice] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [currentPriceCompared, setCurrentPriceCompared] = useState(0);
  const [currentTabDone, setCurrentTabDone] = useState(false);
  const [ratingLoading, setRatingLoading] = useState(false);


  useEffect(() => {
    var clientData = getClientTokenData();
    if (clientData){
      setRatingName(clientData.firstName)
      setRatingEmail(clientData.email)
    }

  }, [])

  const { t, i18n } = useTranslation()
  const [origin, setOrigin] = useState("fr")

  useEffect(() => {
    setOrigin(i18n.language)
  }, [])

  const radios = [
    { name: t('year'), value: 'year' },
    { name: t('month'), value: 'month' },
    { name: t('week'), value: 'week' },
  ];

  const fetchColorsData = () => {
    let query = 'https://public-front-api.therunningcollective.fr/detailed-colors?headCategory=all'

    axios
        .get(query)
        .then((res) => {
          setAvalaibleColors(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
  };

  useEffect(() => {
    fetchColorsData();
  }, []);


  useEffect(() => {
    if(radioValue == "year" && historyPrices && historyPrices.length > 0) {

      setMinPrice(Math.min(...historyPrices.map(item => item.bestPrice)));
      setMaxPrice(Math.max(...historyPrices.map(item => item.bestPrice)));

      let average = 0
      historyPrices.map(price => {
        average += price.bestPrice
      })
      let sumAverage = (average / historyPrices.length).toFixed(2)
      let currentSave = (historyPrices[historyPrices.length-1].bestPrice - sumAverage).toFixed(2)
      setAveragePrice(sumAverage)
      setAverageHistory(currentSave)
      setCurrentPriceCompared(currentSave)
    }
  }, [radioValue, historyPrices]);


  const getHistoryPriceLabel = (historyPrices) => {
    let labels = []
    const formatter = new Intl.DateTimeFormat(i18n.language == "be" ? "fr" : i18n.language, { month: 'short' });

    historyPrices.map(price => {
      let date = new Date(price.historyDate)
      let str = date.getDate() + " " + formatter.format(date).substring(0, 3)
      labels.push(str)
    })
    return labels
  }

  const getHistoryPriceData = (historyPrices) => {
    let labels = []

    historyPrices.map(price => {
      labels.push(price.bestPrice)
    })
    return labels
  }

  const orderPricesHistoryData = (declination, historyPrices, productName) => {
    if(historyPrices && historyPrices.length > 0 && avalaibleColors.length > 0){
      let data = historyPrices.sort((a,b) => new Date(a.historyDate).getTime() - new Date(b.historyDate).getTime())
      let color = getRGBColorFromName(declination.color, avalaibleColors)
      let rgbBackground = 'rgba('+color.r+','+color.g+','+color.b+','+'0.2)'

      const testData = {
        labels: getHistoryPriceLabel(data),
        datasets: [
          {
            label: productName + " ("+ data[0].reference + ")",
            data: getHistoryPriceData(data),
            fill: true,
            backgroundColor: declination.color == "Blanc" ? "rgba(75,192,192,0.2)" : rgbBackground,
            borderColor: declination.color == "Blanc" ? "rgba(75,192,192,1)" : getHexaColorFromName(declination.color, avalaibleColors),
          },
        ]
      };

      return testData
    }
  }

  const chartDataOptions = {
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {

      tooltip: {
        callbacks: {
          label: function(context) {
            return context.parsed.y + "€";
          }
        }
      }
    },
    scales: {
      x: {
        ticks:{
          autoSkip: true,
          maxTicksLimit: 10
        }
      },
      y: {
        suggestedMin: currentDeclination.price * 0.7,
        suggestedMax: currentDeclination.price,
        ticks: {
          stepSize: Math.round(currentDeclination.price / 10),
          // Include a dollar sign in the ticks
          callback: function(value, index, ticks) {
            return value + '€';
          }
        }
      }
    }
  }

  const fetchMerchandsData = () => {
    let query = 'https://public-front-api.therunningcollective.fr/merchants'

    axios
        .get(query)
        .then((res) => {
          setAvalaibleMerchands(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
  };

  const fetchPosts = (slugs) => {
    if (slugs && slugs.length > 0) {
      let axiosRequestArray = []

      slugs.map(slug => {
        let query = 'https://blog.therunningcollective.fr/wp-json/wp/v2/posts?slug='+slug+'&_embed'
        axiosRequestArray.push(axios.get(query))
      })
      axios.all(axiosRequestArray).then(axios.spread((...responses) => {
        if (responses.length > 0){
          let posts = []
          responses.map(response => {
            posts.push(response.data[0])
          })
          setCurrentPosts(posts)
        }
        // use/access the results
      })).catch(errors => {
        // react on errors.
        console.log(errors);
      })
    }
  };

  const ratingChanged = (newRating) => {
    setRatingStars(newRating)
  };

  const sendRating = () => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setRatingLoading(true)
    
    if(ratingMessage == null || ratingMessage == "" ){
      setRatingError("Message manquant")
    } else if(ratingEmail == null || ratingEmail == "" || !re.test(ratingEmail) ){
      setRatingError("Email manquant")
    } else if(ratingName == null || ratingName == "" ){
      setRatingError("Nom manquant")
    } else if(ratingStars == null){
      setRatingError("Note manquante")
    } else {
      setRatingError(null)
      let ratingMsg = {
        name: ratingName,
        email: ratingEmail,
        message: ratingMessage,
        stars: ratingStars,
        reference: product.shortURL,
        productName: product.name
      }
      fetch('https://public-front-api.therunningcollective.fr/product-rating', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(ratingMsg)
      }).then(() => {
            ReactGA.event({
              category: 'Newsletter',
              action: 'Rating product for ' + product.name + 'with stars : ' + ratingStars
            });
        setRatingSuccess(t('savedrate'));
            sleep(2000).then(() => {
              document.location.reload();
              setRatingLoading(false)
            });
          }
      ).catch( err =>{
        console.log(err);
        setRatingLoading(false);
      }
      )
    }
  };

  const generateVoucher = (voucherName) => {
    if (voucherName === "adidastogenerate" || voucherName === "adidastogenerateextra"){
      return adidasVoucher
    } else {
      return voucherName
    }
  }

  const [myMap, setMapState] = useState(new Map());

  const fetchVoucherInfo = async (slug, reference, merchandName) => {
    let query = 'https://public-front-api.therunningcollective.fr/voucher-info?productName='+slug+'&reference='+reference+'&merchandName='+merchandName
    const response = await fetch(query);
    const json = await response.json();
    if (json){
      setMapState(map => new Map(map.set(json.merchand.name, json.description)));
    }
  }

  const [othersRetailers, setOthersRetailers] = useState([])

  useEffect(() => {
    fetchMerchandsData();
    fetchPosts(posts);
  }, [posts]);

  useEffect(() => {
    if (currentDeclination.analysedRetailers){
      let retailers = currentDeclination.analysedRetailers.filter(retailer => {return retailer.retailerName !== currentDeclination.bestRetailer.retailerName})
      retailers = retailers.filter(retailer => {return retailer.active})
      retailers = retailers.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0))
      retailers = retailers.map(retailer => {
        let r = retailer
        let show = true
        if (r.retailerName === 'adidas' && !showVoucher) {
          show = false
        }
        r.showVoucher = show
        if (origin == "fr"){
          //fetchVoucherInfo(product.shortURL, currentDeclination.reference, r.retailerName)
        }
        return r
      })
      if(retailers.length == 0 && !currentTabDone){
        setCurrentTab("productDescription")
        setCurrentTabDone(true)
      } else {
        setCurrentTab("otherSizes")
      }
      setOthersRetailers(retailers)
    } else {
      setOthersRetailers([])
      setCurrentTab("productDescription")
    }
  }, [currentDeclination, origin])

  const isSelectedUserSize = (size) => {
    if (size == selectedUserSize) {
      return {border: "solid 2px #7ac066", borderRadius: "5px", marginBottom: '5px',}
    }
    return {backgroundColor: "#f1f2f6", borderRadius: "5px", marginBottom: '5px',}
  }

  return (
    <div id="othersSizes" className={`description-review-area ${spaceBottomClass}`} ref={othersSizesRef}>
      <div className="container">
        <div className="description-review-wrapper">
          <Tab.Container activeKey={currentTab} >
            <Nav variant="pills" className="description-review-topbar">
              {product.description.length > 0 ?
                  <Nav.Item>
                    <Nav.Link eventKey="productDescription" onClick={() => setCurrentTab("productDescription")}>
                      {t('descriptionProduct')}
                    </Nav.Link>
                  </Nav.Item>
                  : null}
              {othersRetailers.length > 0 ?
                <Nav.Item>
                  <Nav.Link eventKey="otherSizes" onClick={() => setCurrentTab("otherSizes")}>
                    {t('othersSizes')}
                  </Nav.Link>
                </Nav.Item>
                  : null}
              {historyPrices && historyPrices.length > 0 ?
                  <Nav.Item>
                    <Nav.Link eventKey="historyPrices" onClick={() => setCurrentTab("historyPrices")}>
                      {t('historical')}
                    </Nav.Link>
                  </Nav.Item>
                  : null}
              {currentPosts && currentPosts.length && currentPosts.length > 0  && (i18n.language == "fr" || i18n.language == "be") ?
                  <Nav.Item>
                    <Nav.Link eventKey="currentPosts" onClick={() => setCurrentTab("currentPosts")}>{t('articles')} ({currentPosts.length})</Nav.Link>
                  </Nav.Item>
                  : null}
                  <Nav.Item>
                    <Nav.Link eventKey="productReviews" onClick={() => setCurrentTab("productReviews")}>{t('rates')}{productRating && productRating.length > 0 ? " (" + productRating.length + ")" : null}</Nav.Link>
                  </Nav.Item>

            </Nav>
            <Tab.Content className="description-review-bottom">
              {product.description.length > 0 ?
              <Tab.Pane eventKey="productDescription">
                <h2 style={{fontSize: '15px', lineHeight: '23px', color: '#333'}}>{product.description}</h2>
              </Tab.Pane>
                  : null }
              {historyPrices && historyPrices.length > 0 ?
                  <Tab.Pane eventKey="historyPrices">
                    <div style={{border: 'solid 2px', padding: '5px'}}>
                      <div>
                        <ButtonGroup className="mb-2" style={{marginTop: '5px', marginLeft: '5px'}}>
                          {radios.map((radio, idx) => (
                              <ToggleButton
                                  key={idx}
                                  id={`radio-${idx}`}
                                  type="radio"
                                  variant="secondary"
                                  name="radio"
                                  value={radio.value}
                                  checked={radioValue === radio.value}
                                  onChange={(e) => setRadioValue(e.currentTarget.value)}
                                  onClick={(e) => setHistoryPeriod(e.target.value)}
                                  style={{fontSize: '10px'}}
                              >
                                {radio.name}
                              </ToggleButton>
                          ))}
                        </ButtonGroup>
                      </div>
                      {
                        historyPrices && historyPrices.length > 0 && avalaibleColors.length > 0 ?
                            <div>
                              <Line data={orderPricesHistoryData(currentDeclination, historyPrices, product.name)} options={chartDataOptions} style={{maxHeight: '300px'}}/>
                              <div className="row" style={{paddingLeft: "20px", paddingRight: '20px', marginTop: '10px', marginBottom: '15px'}}>
                                <div className="col-md-4" style={{padding: '5px 15px 5px 15px'}}>
                                  <div style={{backgroundColor: '#f7f7f7', padding: '15px'}}>
                                    <div style={{fontSize: '18px'}}><b>{averagePrice} €</b></div>
                                    <span style={{fontSize: '10px'}}>{t('averageprice')}</span>
                                  </div>
                                </div>
                                <div className="col-md-4" style={{padding: '5px 15px 5px 15px'}}>
                                  <div style={{backgroundColor: '#f7f7f7', padding: '15px'}}>
                                    <div style={{fontSize: '18px'}}><b>{minPrice} - {maxPrice} €</b></div>
                                    <span style={{fontSize: '10px'}}>{t('minmaxprice')}</span>
                                  </div>
                                </div>
                                <div className="col-md-4" style={{padding: '5px 15px 5px 15px'}}>
                                  <div style={{backgroundColor: '#f7f7f7', padding: '15px'}}>
                                    { currentPriceCompared <= 0 ?
                                        <div>
                                          <div style={{fontSize: '18px', color: 'green'}}><b>{currentPriceCompared} €</b></div>
                                          <span style={{fontSize: '10px'}}>{t('currentPrice')}</span>
                                        </div>
                                        :
                                        <div>
                                          <div style={{fontSize: '18px', color: 'orange'}}><b>+ {currentPriceCompared} €</b></div>
                                          <span style={{fontSize: '10px'}}>{t('currentPrice')}</span>
                                        </div>
                                    }

                                  </div>
                                </div>
                              </div>
                            </div>
                            : null
                      }
                    </div>
                  </Tab.Pane>
                  : null }
              {othersRetailers.length > 0 ?
                  <Tab.Pane eventKey="otherSizes">
                    <div className="product-anotherinfo-wrapper">
                      <div className="pro-details-size-color">
                        <div className="pro-details-size" id="othersSizesSM">
                          {othersRetailers.length > 0 ? othersRetailers.map(retailer =>{
                                if (retailer.active) {
                                  return(
                                      <div key={retailer.retailerName}>
                                        <Row key={retailer.retailerName} className="rowRetailer">
                                          <Col xs={6}>
                                            <img
                                                src={getMerchandLogoFromName(retailer.retailerName, avalaibleMerchands)}
                                                className="merchand-logo-url"
                                                alt=""
                                                style={{marginBottom: "10px"}}
                                            />
                                            <div>
                                              <b style={{fontSize: "18px"}}>Prix : </b>
                                              <span style={{color: "#4fa0a6", fontSize: "16px"}}>{retailer.price}€</span>
                                            </div>
                                            <div>
                                              <b>Délais de livraisons :</b> &nbsp;
                                              {
                                                getMerchandDeliveryTimeFromName(retailer.retailerName, avalaibleMerchands, t, i18n)
                                              }
                                            </div>
                                          </Col>
                                          <Col xs={6}>
                                            {
                                              product.headCategory !== "/products/accessories" &&  product.headCategory !== "/products/nutrition" && product.headCategory !== "/products/electronic" ?
                                                  <div className="pro-details-size-content">
                                                    Tailles disponibles : &nbsp;
                                                    {
                                                      retailer.sizes ?
                                                          sortSize(retailer.sizes).map((singleSize, key) => {
                                                            if (singleSize !== "Votretaille" && singleSize !== "") {
                                                              return (
                                                                  <a
                                                                      href={null}
                                                                      style={{cursor: "pointer !important"}}
                                                                      rel="noopener noreferrer"
                                                                      target="_blank"
                                                                      onClick={() => {
                                                                        if(retailer.showVoucher  && retailer.vouchers && retailer.vouchers.length === 1){
                                                                          redirectionWithVoucher(retailer, origin, retailer.vouchers[0].name, retailer.retailerName)
                                                                        } else {
                                                                          redirection(retailer, origin)
                                                                        }
                                                                        ReactGA.event({
                                                                          category: 'Product',
                                                                          action: 'Redirect to ' + retailer.retailerName
                                                                        });
                                                                        ReactGA.event({
                                                                          category: 'Product',
                                                                          action: 'Redirect to ' + retailer.retailerName + " for product : " + product.name
                                                                        });
                                                                        ReactGA.event({
                                                                          category: 'Product',
                                                                          action: 'Redirect to ' + retailer.retailerName + " for product : " + product.name + " by size " + singleSize
                                                                        });
                                                                        ReactPixel.track('AddToCart', {
                                                                          content_name: product.name,
                                                                          content_type: 'product',
                                                                          content_category: product.categories && product.categories.length > 0 ? product.categories[0] : "produits",
                                                                          content_ids: currentDeclination.reference,
                                                                          value: retailer.price,
                                                                          currency: 'EUR',
                                                                        });
                                                                      }}
                                                                  >
                                                                    <label
                                                                        style={{}}
                                                                        className={`pro-details-size-content--single`}
                                                                        style={isSelectedUserSize(singleSize)}
                                                                        key={key}
                                                                    >
                                                                      <div className="size-name" style={{paddingTop: "10px"}}>{singleSize == "XXL" ? "2XL" : singleSize}</div>
                                                                    </label>
                                                                  </a>
                                                              );
                                                            } else { return null }
                                                          })
                                                          : null
                                                    }
                                                  </div>
                                                  : null
                                            }
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col xs={retailer.showVoucher  && retailer.vouchers && retailer.vouchers.length === 1 ? 6 : 12}>
                                            <div className="pro-details-quality">
                                              <div className="pro-details-cart ml-0" style={{margin: "0"}}>
                                                <a
                                                    href={null}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    style={{borderRadius: "5px",
                                                      width: retailer.showVoucher  && retailer.vouchers && retailer.vouchers.length === 1 ? "100%" : "100%"}}
                                                    onClick={() => {
                                                      if(retailer.showVoucher  && retailer.vouchers && retailer.vouchers.length === 1){
                                                        redirectionWithVoucher(retailer, origin, retailer.vouchers[0].name, retailer.retailerName)
                                                      } else {
                                                        redirection(retailer, origin)
                                                      }
                                                      ReactGA.event({
                                                        category: 'Product',
                                                        action: 'Redirect to ' + retailer.retailerName
                                                      });
                                                      ReactGA.event({
                                                        category: 'Product',
                                                        action: 'Redirect to ' + retailer.retailerName + " for product : " + product.name
                                                      });
                                                      ReactPixel.track('AddToCart', {
                                                        content_name: product.name,
                                                        content_type: 'product',
                                                        content_category: product.categories && product.categories.length > 0 ? product.categories[0] : "produits",
                                                        content_ids: currentDeclination.reference,
                                                        value: retailer.price,
                                                        currency: 'EUR',
                                                      })
                                                    }}
                                                >
                                                  Voir l'offre
                                                </a>
                                              </div>
                                            </div>
                                          </Col>
                                          {retailer.showVoucher  && retailer.vouchers && retailer.vouchers.length === 1 ?
                                          <>
                                            <Col xs={6} onClick={() => {generateVoucher(retailer.vouchers[0].name)}}>
                                              <div style={{
                                                borderRadius: "5px",
                                                padding: "10px 10px 5px 10px",
                                                color: "#4fa0a6",
                                                fontSize: "18px",
                                                border: "3px dashed #4fa0a6",
                                                borderImage: "https://img.therunningcollective.fr/scissors_01-svg",
                                                textAlign: "center"
                                              }}>
                                                <div style={{
                                                  float: "left",
                                                  padding: "0px",
                                                  margin: "-21px 0px -10px",
                                                  background: "#fff",
                                                  fontSize: "13px"
                                                }}><ScissorsLogo style={{height: "20px",
                                                  width: "20px",
                                                  transform: "rotate(90deg)"}}/></div>
                                                {generateVoucher(retailer.vouchers[0].name)}
                                              </div>
                                            </Col>
                                            <Col xs={6}></Col>
                                            <Col xs={6}>
                                                <div 
                                                    style={{
                                                        color: "#4fa0a6",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    N'oubliez pas d'utiliser le code de réduction pour bénéficier de cette offre.
                                                </div>
                                            </Col>
                                          </>
                                              
                                              :
                                              null
                                          }
                                        </Row>
                                        <hr/>
                                      </div>
                                  )
                                } else {
                                  return (<span>{t('nosize')}</span>)
                                }
                              }) :
                              <span>{t('nosize')}</span>}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                   : null}
              {currentPosts.length > 0 ?
                  <Tab.Pane eventKey="currentPosts">
                    {currentPosts.map(post =>{ if(!post || !post.id){
                      return
                    } else {return (
                            <div className="row" key={post.id}>
                              <div className="col-lg-12">
                                <div className="review-wrapper">
                                  <div className="single-review">
                                    <div className="review-img col-lg-2">
                                      <Link to={process.env.PUBLIC_URL + '/post/' + post.slug}>
                                        <img
                                            className="review-img-content"
                                            src={post._embedded['wp:featuredmedia']['0'].source_url}
                                            alt={post._embedded['wp:featuredmedia']['0'].source_url}
                                        />
                                      </Link>
                                    </div>
                                    <div className="col-lg-10 review-content">
                                      <div className="review-top-wrap">
                                        <div className="review-left">
                                          <div className="review-name">
                                            <h4>
                                              <Link to={process.env.PUBLIC_URL + '/post/' + post.slug}>
                                                {parse(post.title.rendered)}
                                              </Link>
                                            </h4>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="review-bottom">
                                        <p>
                                          <Link to={process.env.PUBLIC_URL + '/post/' + post.slug}>
                                            {parse(post.excerpt.rendered)}
                                          </Link>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        )}
                  })}

                  </Tab.Pane>
                  : null }
                  <Tab.Pane eventKey="productReviews">
                    <div className="row">
                      <div className="col-lg-7">
                        {productRating && productRating.length > 0 ? productRating.map((rate, index) => {
                          return (
                                <div className="review-wrapper" key={index}>
                                  <div className="single-review">
                                    <div className="review-content">
                                      <div className="review-top-wrap">
                                        <div className="review-left">
                                          <div className="review-name">
                                            <h4>{rate.name}</h4>
                                          </div>
                                          <div className="review-rating">
                                            {(() => {
                                              const rows = [];
                                              for (let i = 0; i < rate.stars; i++) {
                                                rows.push(<i className="fa fa-star" key={i} style={{color: '#ffa900'}}/>);
                                              }
                                              for (let i = 0; i < Math.round(5-rate.stars); i++) {
                                                rows.push(<i className="fa fa-star" key={i} style={{color: 'grey'}}/>);
                                              }
                                              return rows;
                                            })()}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="review-bottom">
                                        <p>
                                          {rate.message}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                          )

                        }) : null }
                      </div>
                      <div className="col-lg-5">
                        {ratingSuccess == null ?
                            <div className="ratting-form-wrapper pl-50">
                              <h3>{t('addrate')}</h3>
                              <div className="ratting-form">
                                <form action="#">
                                  <div className="star-box">
                                    <span>{t('yourrate')}</span>
                                    <div className="ratting-star">
                                      <ReactStars
                                          count={5}
                                          onChange={ratingChanged}
                                          size={15}
                                          activeColor="#ffa900"
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="rating-form-style mb-10">
                                        <input placeholder={t('firstname')} type="text" onChange={(e) => setRatingName(e.target.value)} value={ratingName}/>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="rating-form-style mb-10">
                                        <input placeholder="Email" type="email" onChange={(e) => setRatingEmail(e.target.value)} value={ratingEmail}/>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="rating-form-style form-submit">
                                <textarea
                                    name={t('yourratetxt')}
                                    placeholder="Message"
                                    defaultValue={""}
                                    onChange={(e) => setRatingMessage(e.target.value)}
                                />
                                        {
                                          !ratingLoading ?
                                          <input type="button" defaultValue={t('share')} onClick={sendRating}/>
                                          : 
                                          <div style={{textAlign: 'center'}}>
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden"></span>
                                            </Spinner>
                                        </div>
                                        }
                                        {ratingError ? <div style={{color: 'red'}}>{ratingError}</div> : null}
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            :
                            <div className="ratting-form-wrapper pl-50">
                              <span><b>{ratingSuccess}</b></span>
                            </div>
                        }

                      </div>
                    </div>
                  </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

/*function getDiscount(declinationPrice, retailerPrice){
  return Math.round((declinationPrice - retailerPrice) / declinationPrice * 100)
}*/

export function getMerchandLogoFromName(merchandName, merchands){
  if (merchands && merchands.length > 0) {
    let merchand = merchands.filter(merchand => { return merchand.name === merchandName})[0]
    return merchand && merchand.logoUrl ? merchand.logoUrl : null
  }
}

export function getMerchandReturnTimeFromName(merchandName, merchands){
  if (merchands && merchands.length > 0) {
    let merchand = merchands.filter(merchand => { return merchand.name === merchandName})[0]
    return merchand && merchand.returningTime ? merchand.returningTime : null
  }
}

export function getMerchandDeliveryTimeFromName(merchandName, merchands, t, i18n){
  if (merchands && merchands.length > 0) {
    let merchand = merchands.filter(merchand => { return merchand.name === merchandName})[0]
    if(merchand){
      var deliveryTime = merchand.deliveryTime
      if (merchand && merchand.deliveryTime){
        deliveryTime = merchand.deliveryTime.replace("à", t('à'))
        deliveryTime = deliveryTime.replace("jours", t('jours'))
        if(i18n.language == "it"){
          deliveryTime = "Da " + merchand.deliveryTime
        } else if(i18n.language == "es"){
          deliveryTime = "De " + merchand.deliveryTime
        }
      }
      return merchand && merchand.deliveryTime ? deliveryTime : null
    }
  }
}

ProductDescriptionTab.propTypes = {
  currentDeclination: PropTypes.object,
  productFullDesc: PropTypes.string,
  product: PropTypes.object,
  posts: PropTypes.any,
  spaceBottomClass: PropTypes.string,
  othersSizesRef: PropTypes.any,
  productRating: PropTypes.array,
  historyPrices: PropTypes.any,
  setHistoryPeriod: PropTypes.func,
  setCurrentTab: PropTypes.func,
  currentTab: PropTypes.any,
  setAverageHistory: PropTypes.func,
  selectedUserSize: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData,
    cartItems: state.cartData,
    wishlistItems: state.wishlistData,
    compareItems: state.compareData
  };
};

export default connect(mapStateToProps)(ProductDescriptionTab);
