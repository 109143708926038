import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import Swiper from "react-id-swiper";
import SectionTitle from "../../components/section-title/SectionTitle";
import ProductGrid from "./ProductGrid";
import axios from "axios";
import {Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {isMobileOnly} from "react-device-detect";

const RelatedProductSlider = ({ spaceBottomClass, category, headCategory, product, origin }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation()

  const fetchData = React.useCallback(() => {
    // your function using screenSize here
    if(origin && origin == "be"){
      origin = "fr"
    }

    let category = product.categories[0]
    if (category == "Black Friday" || "Soldes" && product.categories.size > 1){
      category = product.categories[1]
    }

    let query
    if (headCategory == "/products/shoes"){
      query = 'https://public-front-api.therunningcollective.fr/similar-products?productSlug='+product.shortURL
    } else {
      query = 'https://public-front-api.therunningcollective.fr/products?filters=homePage&headCategory='+headCategory+'&subCategory='+category+'&genders='+product.gender
    }

    axios
        .post(query)
        .then((res) => {
          if (res && res.data.products && res.data.products.length > 0){
            let filteredData = res.data.products
            filteredData = filteredData.filter(p => p.shortURL !== product.shortURL)
            filteredData = filteredData.filter(p => (p.gender === product.gender || p.gender === 'Unisex') )
            setProducts(filteredData);
            setLoading(false)
          } else {
            let query = 'https://public-front-api.therunningcollective.fr/products?headCategory='+headCategory+'&subCategory='+category+'&genders='+product.gender
            if (product.shopType && product.shopType.length > 0){
              query = query + "&shopType=" + product.shopType[0]
            }
            axios
              .post(query)
              .then((res) => {
                if (res && res.data.products && res.data.products.length > 0){
                  let filteredData = res.data.products
                  filteredData = filteredData.filter(p => p.shortURL.replace(/\?.*/,'') !== product.shortURL)
                  filteredData = filteredData.filter(p => (p.gender === product.gender || p.gender === 'Unisex') )
                  if (filteredData.length >= 8) {
                    filteredData.length = 8;
                  }
                  setProducts(filteredData);
                  setLoading(false)
                }
              })
              .catch((err) => {
                console.log(err);
              })
                }
        })
        .catch((err) => {
          console.log(err);
        })
  }, [headCategory, category, product]);

  useEffect(() => {
    fetchData(headCategory);
  }, [headCategory, fetchData]);

  const settings = {
    loop: false,
    slidesPerView: 4,
    grabCursor: true,
    breakpoints: {
      1024: {
        slidesPerView: 2
      },
      768: {
        slidesPerView: 3
      },
      640: {
        slidesPerView: 2
      },
      320: {
        slidesPerView: 2
      }
    }
  };

  return (
    <div
      className={`related-product-area ${
        spaceBottomClass ? spaceBottomClass : ""
      }`}
    >
      {!loading ?
          products.length > 0 ?
                <div className="container-fluid" style={{paddingLeft: isMobileOnly ? "0px": "15px", paddingRight: isMobileOnly ? "0px": "15px"}}>
                  <SectionTitle
                      titleText={t('similarproducts')}
                      positionClass="text-left"
                      spaceClass="mb-10 ml-15"
                  />
                  <div className={"row" + isMobileOnly ? " tabproducteleven" : ""}>
                    <Swiper {...settings}>
                      <ProductGrid
                          category={category}
                          limit={6}
                          products={products}
                          sliderClassName="swiper-slide"
                      />
                    </Swiper>
                  </div>
                </div>
          : <span></span>

      :
          <div style={{textAlign: 'center'}}>
            <Spinner animation="border" role="status" >
              <span className="visually-hidden"></span>
            </Spinner>
          </div>}

    </div>
  );
};

RelatedProductSlider.propTypes = {
  category: PropTypes.string,
  product: PropTypes.object,
  spaceBottomClass: PropTypes.string
};

export default RelatedProductSlider;
