import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setCurrency } from "../../redux/actions/currencyActions";
import { multilanguage } from "redux-multilanguage";
import Logo from "../../components/header/Logo";
import IconGroup from "../../components/header/IconGroup";
import NavMenu from "../../components/header/NavMenu";
import MobileMenu from "../../components/header/MobileMenu";
import {isMobileOnly, isTablet} from "react-device-detect";
import {IconButton, InputAdornment, InputBase, Paper, Tooltip} from "@mui/material";
import {Search} from "@material-ui/icons";
import ReactPixel from "react-facebook-pixel";
import {apiConversionRequest, getClientTokenData, GetUserData} from "../../App";
import { useTranslation } from 'react-i18next';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AliceCarousel from "react-alice-carousel";
import axios from "axios";
import ReactGA from "react-ga4";
import { Col, OverlayTrigger, Row } from "react-bootstrap";
import { GrFavorite } from "react-icons/gr";

export const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    bottom: "10px",
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: 'black',
  },
});

export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontFamily: "ITC Avant Garde Gothic",
      fontSize: theme.typography.pxToRem(14),
      color: 'rgba(O, 0, 0, 0.7)',
      '&.Mui-selected': {
        color: '#000',
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
      },
    }),
);

const HeaderTwo = ({
  location,
  offer,
  setProductSearch,
  setRaceSearch,
  productSearch,
    strings,
                     podcastSearch,
                     setPodcastSearch,
                     homePage,
                     favoriteProducts
}) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [isPortrait, setIsPortrait] = useState(false);
  const [value, setValue] = useState(0);
  const [tags, setTags] = useState([]);
  const [shopType, setShopType] = useState(null);
  const [productsType, setProductsType] = useState(null);
  const [isAmbassador, setIsAmbassador] = useState(false);
  const [favLength, setFavLength] = useState(0);

  useEffect(() => {
    let token = getClientTokenData();
    if (token && token.isAmbassador){
      setIsAmbassador(true)
    }
  }, [])

  useEffect(() => {
    console.log(location.pathname)
    if (location.pathname == "/"){
      setValue(0)
      setShopType("home")
    }
    if (location.pathname == "/athletisme"){
      setValue(1)
      setShopType("athletisme")
    }
    if (location.pathname == "/running"){
      setValue(2)
      setShopType("running")
    }
    if (location.pathname == "/trail"){
      setValue(3)
      setShopType("trail")
    }
    if (location.pathname == "/products/apparel"){
      setProductsType("apparel")
    }
    if (location.pathname == "/products/shoes"){
      setProductsType("shoes")
    }
    if (location.pathname == "/products/spikes"){
      setProductsType("spikes")
    }
  }, [])

  const { t } = useTranslation();
  const theme = createTheme({
    centerText: {
      textAlign: "left"
    },
    typography: {
      fontFamily: [
        'ITC Avant Garde Gothic'
      ].join(','),
    },
    palette: {
      primary: {
        main: '#000000',
      },
    },
  });

  const handleChange = (event, newValue) => {
    if(newValue == 0){
      window.location.href="/";
    }
    if(newValue == 1){
      window.location.href="/athletisme";
    }
    if(newValue == 2){
      window.location.href="/running";
    }
    if(newValue == 3){
      window.location.href="/trail";
    }
  };

  useEffect(() => {
    const header = document.querySelector(".sticky-bar");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const handleSearch = () => {
    let resWithoutSpace = productSearch.replaceAll(" ", "-")
    let res = resWithoutSpace.toLowerCase()
    window.location.assign(process.env.PUBLIC_URL + '/products?page=1&keywords='+res)
    ReactPixel.track('Search', {
      search_string: resWithoutSpace,
      content_type: 'product',
    })
    GetUserData(true).then(data =>
        apiConversionRequest(data.query, "Search", {search: resWithoutSpace})
    )
  }

  const handlePodcastSearch = () => {
    let resWithoutSpace = productSearch.replaceAll(" ", "-")
    let res = resWithoutSpace.toLowerCase()
    window.location.assign(process.env.PUBLIC_URL + '/new-podcasts?page=1')
    ReactPixel.track('Search', {
      search_string: resWithoutSpace,
      content_type: 'product',
    })
    GetUserData(true).then(data =>
        apiConversionRequest(data.query, "Search", {search: resWithoutSpace})
    )
  }

  useEffect(() => {
    const portrait = window.matchMedia("(orientation: portrait)").matches;
    setIsPortrait(portrait)
  }, [])

  window.matchMedia("(orientation: portrait)").addEventListener("change", e => {
    const portrait = e.matches;
    if (portrait) {
      // do something
      setIsPortrait(true)
    } else {
      // do something else
      setIsPortrait(false)
    }
  });

  useEffect(() => {
    setFavLength(favoriteProducts && favoriteProducts.length ? favoriteProducts.length : 0)
  }, [favoriteProducts])

  return (
      <ThemeProvider theme={theme}>
      <header className="header-area clearfix header-hm9">
      <div className="container-fluid">
        <div className="header-top-area d-none d-lg-block" style={{borderBottom: "none"}}>
          <div className="row">

            <div className="col-lg-3 d-none d-lg-block text-center">
              {/* header logo */}
              <Logo
                imageUrl="/assets/img/logo/logo.png"
                location={location}
                offer={offer}
              />
            </div>
            <div className="col-lg-5 col-md-4 col-12">
              <NavMenu />
            </div>
            <div className="col-lg-3 col-md-4 col-12">
              <div style={{textAlign: 'center', width: "100%", marginTop: "5px" }}>
                <Row>
                  <Col xs={isAmbassador ? 10 : 12}>
                  {
                    location.pathname.includes("/race") ?
                      <InputBase
                          sx={{
                            "& placeholder": {
                              textAlign: 'left',
                            },
                            "&::placeholder": {
                              textAlign: "left"
                            },
                            "& input::placeholder": {
                              textAlign: "left",
                            },
                          }}
                          style={{padding: "5px", paddingLeft: "30px",  width: "100%", borderRadius: "5px", border: "1px solid lightgray", textAlign: "left", marginBottom: "20px"}}
                          placeholder={t('searchraces')}
                          onChange={(e) => setRaceSearch(e.target.value)}
                          endAdornment={
                            <InputAdornment position="start" style={{paddingLeft: "10px"}}>
                              <Search />
                            </InputAdornment>
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleSearch()
                            }
                          }}
                      /> : location.pathname.includes("podcast") ?
                          <InputBase
                              sx={{
                                "& placeholder": {
                                  textAlign: 'left',
                                },
                                "&::placeholder": {
                                  textAlign: "left"
                                },
                                "& input::placeholder": {
                                  textAlign: "left",
                                },
                              }}
                              style={{padding: "5px", paddingLeft: "30px",  width: "100%", borderRadius: "5px", border: "1px solid lightgray", textAlign: "left", marginBottom: "20px"}}
                              placeholder={t('searchpodcast')}
                              onChange={(e) => setPodcastSearch(e.target.value)}
                              endAdornment={
                                <InputAdornment position="start" style={{paddingLeft: "10px"}}>
                                  <Search />
                                </InputAdornment>
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handlePodcastSearch()
                                }
                              }}
                          />  :                           
                          <InputBase
                            sx={{
                              "& placeholder": {
                                textAlign: 'left',
                              },
                              "&::placeholder": {
                                textAlign: "left"
                              },
                              "& input::placeholder": {
                                textAlign: "left",
                              },
                            }}
                            style={{padding: "5px", paddingTop: "8px", paddingLeft: "30px",  width: "87%", borderRadius: "5px", border: "1px solid lightgray", textAlign: "left", marginBottom: "20px"}}
                            placeholder={t('search')}
                            onChange={(e) => setProductSearch(e.target.value)}
                            endAdornment={
                              <InputAdornment position="start" style={{paddingLeft: "10px"}}>
                                <Search />
                              </InputAdornment>
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleSearch()
                              }
                            }}
                        /> }
                  </Col>
                  <Col xs={isAmbassador ? 2 : 0}>
                    {
                        isAmbassador ?
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip" style={{paddingTop: "20px", textAlign: "center"}}>Ma sélection</Tooltip>}
                          >
                            <>
                              <div
                                style={{paddingTop: "10px", paddingLeft: "20px", cursor: "pointer", position: "absolute"}}
                                className="account-setting-active"
                                onClick={() => window.location.href = "/favorites"}
                              >
                                <GrFavorite size={"1.6rem"}/>
                              </div>
                              {
                                favLength ?
                                <div 
                                  style={{position: "absolute", fontSize: "10px", backgroundColor: "red", width: "15px", height: "15px", color: "white", borderRadius: "50%", top: "5px", right: "10px"}}
                                >
                                  <div style={{marginTop: "-3px"}}>
                                  {favLength}
                                  </div>
                                </div>
                                : null
                              }                          
                            </>
                          </OverlayTrigger>
                        : null
                      }
                  </Col>
                </Row>
              </div>
            </div>
            <div className="col-lg-1 col-md-4 col-12" style={{textAlign: "left", paddingLeft: "0px"}}>
              {/* Icon group */}
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip" style={{paddingTop: "20px", textAlign: "center"}}>Mon profil</Tooltip>}
              >
                <IconGroup isAmbassador={isAmbassador} favLength={favLength}/>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`header-bottom sticky-bar header-res-padding header-padding-2`}
      >
        <div className="container-fluid" style={{padding: "0"}}>
          <div className="row" style={{width:"100%"}}>
            <div className="col-10 d-block d-lg-none" style={{paddingLeft: isMobileOnly ? "0px": "inherit", paddingRight: isMobileOnly ? "0px": "none"}}>
              {/* header logo */}
              <Logo imageUrl="/assets/img/logo/logo.png" location={location} offer={offer} isPortrait={isPortrait}/>
            </div>
            <div className="col-2 d-block d-lg-none" style={{paddingLeft: isMobileOnly ? "0" : ""}}>
              {/* Icon group */}
              <IconGroup  isAmbassador={isAmbassador} favLength={favLength}/>
            </div>
            <div className="col-xl-12 col-lg-12 d-none d-lg-block">
              {/* Nav menu */}
            </div>
            <div className="col-xl-12 col-lg-12 d-lg-block">
              {/* Nav menu */}
              {isMobileOnly ?
                  <div style={{fontSize: '10px', color: '#000', letterSpacing: '1px', textAlign: "left", paddingLeft: "15px"}}>
                    {t('descriptionhead')}
                  </div>

                  : null }
            </div>


          </div>
          {/* mobile menu */}
          <MobileMenu isAmbassador={isAmbassador}/>

          {
            homePage && ((isTablet && isPortrait) || isMobileOnly) ?
                <Box sx={{bgcolor: 'background.paper' }}>
                  <StyledTabs
                      centered
                      value={value}
                      onChange={handleChange}
                  >
                    <StyledTab label={t('home')} />
                    <StyledTab label={t('trackandfield')} />
                    <StyledTab label={t('running')} />
                    <StyledTab label={t('trail')} />
                  </StyledTabs>
                </Box>
             : null
          }

          {
            homePage && ((isTablet && isPortrait) || isMobileOnly) ?
                <div className="firstProducts" style={{backgroundColor: "white"}}>
                  <AliceCarousel
                      mouseTracking
                      items={tags}
                      disableButtonsControls={true}
                      keyboardNavigation={true}
                      renderDotsItem={false}
                      autoWidth={true}
                  />
                </div> : null
          }

          {
            ((isTablet && isPortrait) || isMobileOnly) ?
              location.pathname.includes("/race") ?
                  <Paper
                      sx={{
                        "& placeholder": {
                          textAlign: 'left',
                        },
                        "&::placeholder": {
                          textAlign: "left"
                        },
                        "& input::placeholder": {
                          textAlign: "left",
                        },
                      }}
                      style={{marginTop: '10px', paddingLeft: '10px', paddingRight: '10px', marginBottom: '10px', boxShadow: "none"}}
                  >
                    <IconButton style={{paddingTop: "0px", paddingBottom: "0px"}} aria-label="search" onClick={() => handleSearch()}>
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                        style={{width: "80%"}}
                        placeholder={t('searchraces')}
                        inputProps={{ 'aria-label': 'search google maps' }}
                        onChange={(e) => setRaceSearch(e.target.value)}
                        value={productSearch}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSearch()
                          }
                        }}
                    />
                    <hr style={{margin: "0px 0px 10px 0px", border: "1px solid lightgray"}}/>
                  </Paper>
                  : location.pathname.includes("podcast") ?
                      <Paper
                          sx={{
                            "& placeholder": {
                              textAlign: 'left',
                            },
                            "&::placeholder": {
                              textAlign: "left"
                            },
                            "& input::placeholder": {
                              textAlign: "left",
                            },
                          }}
                          style={{marginTop: '10px', paddingLeft: '10px', paddingRight: '10px', marginBottom: '10px', boxShadow: "none"}}
                      >
                        <IconButton style={{paddingTop: "0px", paddingBottom: "0px"}} aria-label="search" onClick={() => handleSearch()}>
                          <SearchIcon />
                        </IconButton>
                        <InputBase
                            style={{width: "80%"}}
                            placeholder={t('searchpodcast')}
                            inputProps={{ 'aria-label': 'search google maps' }}
                            onChange={(e) => setPodcastSearch(e.target.value)}
                            value={productSearch}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleSearch()
                              }
                            }}
                        />
                        <hr style={{margin: "0px 0px 10px 0px", border: "1px solid lightgray"}}/>
                      </Paper> :
                  <Paper
                      sx={{
                        "& placeholder": {
                          textAlign: 'left',
                        },
                        "&::placeholder": {
                          textAlign: "left"
                        },
                        "& input::placeholder": {
                          textAlign: "left",
                        },
                      }}
                      style={{marginTop: '10px', paddingLeft: '10px', paddingRight: '10px', marginBottom: '10px', boxShadow: "none"}}
                  >
                    <IconButton style={{paddingTop: "0px", paddingBottom: "0px"}} aria-label="search" onClick={() => handleSearch()}>
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                        style={{width: "80%"}}
                        placeholder={t('search')}
                        inputProps={{ 'aria-label': 'search google maps' }}
                        onChange={(e) => setProductSearch(e.target.value)}
                        value={productSearch}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSearch()
                          }
                        }}
                    />
                    <hr style={{margin: "0px 0px 10px 0px", border: "1px solid lightgray"}}/>
                  </Paper>
              : null }
        </div>
      </div>
    </header>
      </ThemeProvider>
  );
};

export function getProfileName(location, t){

  if (location && location.pathname){
    switch (location.pathname) {
      case "/athletisme":
        return t('trackandfield')
      case "/products/spikes":
        return t('trackandfield')
      case "/running":
        return t('running')
      case "/trail":
        return t('trail')
    }
  }

  if (location && location.search) {
    if (location.search.includes("athletisme")){
      return t('trackandfield')
    } else if (location.search.includes("running")) {
      return t('running')
    } else if (location.search.includes("trail")) {
      return t('trail')
    }
  }
}

HeaderTwo.propTypes = {
  setCurrency: PropTypes.func,
  currency: PropTypes.object,
  currentLanguageCode: PropTypes.string,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  offer: PropTypes.object,
  strings: PropTypes.object
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrency: currencyName => {
      dispatch(setCurrency(currencyName));
    }
  };
};

function extractUrl(url){
  const newURL = url.replace('https://app.therunningcollective.fr', process.env.PUBLIC_URL);
  return newURL
}

function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export const BuildFirstProducts = (avalaibleProductsSlides, set) => {
  let newCategoriesItems = []
  avalaibleProductsSlides.map(avalaibleProductsSlide => {
    newCategoriesItems.push(
        <div
            onClick={() => {
              ReactGA.event({
                category: 'FirstProductHomePage',
                action: 'Redirect to ' + avalaibleProductsSlide.title
              });
            }}
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, ${avalaibleProductsSlide.contrast != "" ? avalaibleProductsSlide.contrast : "0"}),rgba(0, 0, 0, ${avalaibleProductsSlide.contrast != "" ? avalaibleProductsSlide.contrast : "0"})) , url(${avalaibleProductsSlide.image})`,
              height: "60px",
              width: "140px",
              backgroundSize: "100%",
              backgroundPositionY: "130px",
              marginLeft: "10px"
            }}>
          <div
              onClick={() => {window.location = extractUrl(avalaibleProductsSlide.url)}}
          >
            <div className="container">
              <div style={{textAlign: "center"}}>
                <div>
                  <div className="slider-content-2 slider-animated-1">
                    <div className="slider-h3 animated" style={{
                      color: avalaibleProductsSlide.textColor ? rgbToHex(avalaibleProductsSlide.textColor.r, avalaibleProductsSlide.textColor.g, avalaibleProductsSlide.textColor.b) : 'black',
                      fontSize: "11px",
                      lineHeight: "15px",
                      textAlign: "center",
                      padding: "1rem"
                    }}>
                      <span className={"trait"} style={{backgroundColor: avalaibleProductsSlide.textColor ? rgbToHex(avalaibleProductsSlide.textColor.r, avalaibleProductsSlide.textColor.g, avalaibleProductsSlide.textColor.b) : 'black'}}/>
                      {avalaibleProductsSlide.title}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

    )
  })
  set(newCategoriesItems)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(multilanguage(HeaderTwo));
