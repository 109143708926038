import PropTypes from "prop-types";
import React, { Fragment } from "react";
import {isMobileOnly, isTablet} from "react-device-detect";

import {slugify} from "../../helpers/product"

const ProductGridSingleTwoCategory = ({
                                category,
                                currency,
                                sliderClassName,
                                spaceBottomClass,
                                colorClass,
                                titlePriceClass, isSide, index
                              }) => {

  return (
      <Fragment>
        <div
            className={isSide ? `col-xl-3 col-md-3 col-lg-3 col-sm-3 ${
                sliderClassName ? sliderClassName : ""
            }` : `col-xl-2 col-md-3 col-lg-3 col-sm-3 ${
                sliderClassName ? sliderClassName : ""
            }`}
        >
          <div
              style={{width: isMobileOnly ? "120px !important" : "230px !important", marginLeft: index == 0 && !isMobileOnly && isSide ? "-15px" : ""}}
              className={`category-wrap-2 ${
                  spaceBottomClass ? spaceBottomClass : ""
              } ${colorClass ? colorClass : ""} `}
          >
            <div className="category-img" style={{paddingTop: "5px", paddingBottom: "5px", 
                border: "1px solid rgb(204, 204, 204)",
                borderRadius:"5px", width: isMobileOnly ? "120px !important" : "230px !important", textAlign: "center", height: isMobileOnly ? "120px !important" : "230px !important", overflow: "hidden"}}>
              <a href={category && category.url ? process.env.PUBLIC_URL + category.url : ""}>
                <img
                    style={{maxWidth: "60px", maxHeight: "60px", height: "30px"}}
                    className="default-img"
                    src={(category.image != "" ? category.image : "https://img.therunningcollective.fr/fond-prochainement-disponible-jpeg-jpeg")}
                    alt={category.title}
                />
              </a>
            </div>
            <div className="category-content-2">
              <div
                  className={`title-price-wrap-2 ${
                      titlePriceClass ? titlePriceClass : ""
                  }`}
              >
                <div className="price-2" style={{textAlign: "left", marginTop: "10px"}}>
                  <h3 style={{lineHeight: "13px", fontSize: "15px"}}>
                    <a href={category && category.url ? process.env.PUBLIC_URL + category.url : ""}>
                      {category.title}
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
  );
};

ProductGridSingleTwoCategory.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItem: PropTypes.object,
  compareItem: PropTypes.object,
  currency: PropTypes.object,
  category: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
  titlePriceClass: PropTypes.string,
  wishlistItem: PropTypes.object
};

export default ProductGridSingleTwoCategory;
